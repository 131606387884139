import React, { useRef } from "react";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { Phone, MessageCircle } from "lucide-react";

import "./contact.css";
import { fadeIn } from "../../variants";

const Contact = () => {
  const form = useRef();
  const phoneNumber = "+917838273237";

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4v5zn0k",
        "template_hnzd9ff",
        form.current,
        "1Ie7I0yCe4UeafZrX"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          e.target.reset();
          alert("Mail Sent!!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/${phoneNumber.replace("+", "")}`, "_blank");
  };

  const handlePhoneClick = () => {
    window.open(`tel:${phoneNumber}`, "_blank");
  };

  return (
    <div className="contact-main" id="contact">
      <div className="contact-flex">
        {/* left-side */}
        <div>
          <div>
            {/* text */}
            <motion.h2
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              animate="show"
              exit="hidden"
              className="connect-heading"
            >
              Contact us
              <div className="contact-under-line" />
            </motion.h2>

            {/* Social Icons */}
            <div className="social-icons">
              <button
                onClick={handleWhatsAppClick}
                className="social-button"
                title="Chat on WhatsApp"
              >
                <MessageCircle className="icon" />
                <span>WhatsApp</span>
              </button>

              <button
                onClick={handlePhoneClick}
                className="social-button"
                title="Call us"
              >
                <Phone className="icon" />
                <span>Call</span>
              </button>
            </div>

            {/* Mail text */}
            <div className="mail-text">Or mail us below</div>

            {/* form */}
            <motion.form
              ref={form}
              onSubmit={sendEmail}
              initial={{ y: "2rem", opacity: 0 }}
              whileInView={{ y: "0", opacity: 1 }}
              transition={{
                duration: 3,
                type: "spring",
              }}
              className="form"
            >
              <div>
                <input
                  type="text"
                  placeholder="Full Name"
                  className="contact-form"
                  name="to_name"
                />
                <input
                  type="text"
                  placeholder="Email"
                  className="contact-form"
                  name="from_name"
                />
              </div>

              <div>
                <div className="textarea-div">
                  <textarea
                    placeholder="Message"
                    className="textarea"
                    name="message"
                  ></textarea>
                </div>
              </div>

              <button type="submit" value="send" className="form-btn">
                <span>Let&apos;s talk</span>
                <span> </span>
                <BsArrowRight />
              </button>
            </motion.form>
          </div>
        </div>

        {/* mid-side */}
        <div>
          <br />
          <br />
          <br />
          <br />
          <div className="line-1 line-pos" />
          <br />
          <div className="line-1 line-pos" />
        </div>

        {/* right-side */}
        <motion.div
          initial={{ x: "2rem", opacity: 0 }}
          whileInView={{ x: "0", opacity: 1 }}
          transition={{
            duration: 4,
            type: "spring",
          }}
          className="c-right"
        >
          <div className="contact-image">
            <img src="./images/11.jpg" alt="" />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
