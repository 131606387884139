import React, { useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";

import "./work.css";
import data from "../../utils/slider.json";
import { sliderSettings } from "../../utils/common";
import WorkDialog from "./WorkDialog";

const Work = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsDialogOpen(true);
  };

  return (
    <section className="r-wrapper" id="work">
      <div className="paddings innerWidth r-container">
        <div className="r-head flexColStart">
          <span className="primaryText">Our Work</span>
          <div className="work-underline" />
          <span className="secondaryText sub-head">
            Experience the Transformation - Our Work, Your Inspiration.
          </span>
        </div>

        <div>
          <Swiper {...sliderSettings}>
            <SliderButtons />
            {data.map((card, i) => (
              <SwiperSlide key={i}>
                <div
                  className="flexColStart r-card"
                  onClick={() => handleProjectClick(card)}
                >
                  <img src={card.image} alt="home" />
                  <span className="secondaryText r-price">
                    <span style={{ color: "orange" }}>{card.status}</span>
                  </span>
                  <span className="primaryText">{card.name}</span>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <WorkDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        project={selectedProject}
      />
    </section>
  );
};

export default Work;

const SliderButtons = () => {
  const swiper = useSwiper();
  return (
    <div className="r-buttons flexCenter">
      <button onClick={() => swiper.slidePrev()}>&lt;</button>
      <button onClick={() => swiper.slideNext()}>&gt;</button>
    </div>
  );
};
