import React from "react";
import "./App.css";
import Nav from "./components/nav/nav";
import Main from "./components/main/main";
import Services from "./components/services/services";
import Work from "./components/work/work";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";

const App = () => {
  return (
    <div className="App">
      <div>
        <Nav />
        <div className="white-gradient" />
        <Main />
      </div>

      <Services />
      <Work />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
