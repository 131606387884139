import React from "react";
import CountUp from "react-countup";
import { motion } from "framer-motion";

import "./main.css";

const Main = () => {
  return (
    <section className="hero-wrapper">
      <div className="paddings innerWidth flexCenter hero-container">
        {/* left side */}
        <div className="flexColStart hero-left">
          <div className="hero-title">
            <div className="orange-circle" />
            <motion.h1
              initial={{ y: "2rem", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 2,
                type: "spring",
              }}
              className="subtitle"
            >
              Building a<br />
              Better World,
              <br />
              Together.
            </motion.h1>
          </div>

          <div className="flexColStart hero-des">
            <span className="secondaryText2">From Concept to</span>
            <span className="secondaryText2">Completion.</span>
          </div>

          <div className="flexCenter stats">
            <div className="flexColCenter stat">
              <span>
                <CountUp end={14} />
                <span>+</span>
              </span>
              <span className="secondaryText">Total Services</span>
            </div>

            <div className="flexColCenter stat">
              <span>
                <CountUp end={50} />
                <span>+</span>
              </span>
              <span className="secondaryText">Projects Completed</span>
            </div>

            <div className="flexColCenter stat">
              <span>
                <CountUp end={4} />
                <span>+</span>
              </span>
              <span className="secondaryText">On-going Projects</span>
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="flexCenter hero-right">
          <motion.div
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 2,
              type: "spring",
            }}
            className="image-container"
          >
            <img src="./images/main-image2.png" alt="hero" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Main;
