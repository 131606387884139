import React from "react";
import { motion } from "framer-motion";

import "./about.css";
import { navVariants } from "../../utils/motion";

const About = () => {
  return (
    <div className="about-main" id="about">
      {/* left-side */}
      <div className="about-flex about-left">
        <div className="r-head flexColStart">
          <span className="headingText">About</span>
          <div className="under-line" />
        </div>

        <div>
          <motion.div
            variants={navVariants}
            initial="hidden"
            whileInView="show"
            className="about-image"
          >
            <img src="./images/mb-pic.png" alt="hero" />
          </motion.div>

          <motion.h4
            initial={{ x: "-2rem", opacity: 0 }}
            whileInView={{ x: "0", opacity: 1 }}
            transition={{
              duration: 4,
              type: "spring",
            }}
            className="name"
          >
            Mayank Kumar Gupta
          </motion.h4>
        </div>
      </div>

      {/* right-side */}
      <motion.div
        initial={{ x: "-2rem", opacity: 0 }}
        whileInView={{ x: "0", opacity: 1 }}
        transition={{
          duration: 6,
          type: "spring",
        }}
        className="about-right aboutText"
      >
        <p>
          <span className="about-span">
            CMD of GY Consultant & Constructions
          </span>
          <br />
          Corporate Member of the Institution of Engineers India (IEI), Licensed
          Architect in Uttar Pradesh State for Buildings Architectural Plans &
          Approvals and Life Member of the Indian Society of Structural
          Engineers (ISSE).
          <br />
          <br />
          <span className="about-span">Professional Overview</span>
          <br />
          Currently serving as an independent Structural Consultant and Licensed
          Government Engineer for plan approvals. Highly skilled in the analysis
          and design of RCC (Reinforced Cement Concrete) and Steel structures
          across diverse project types, including educational, institutional,
          assembly, residential, office buildings, industrial warehouses, and
          sheds.
          <br />
          <br />
          Possess comprehensive expertise in Indian Standards Codes and
          regulations, ensuring structural analysis, calculations, and design of
          RCC and Steel structures align with Indian Design Standards.
          Proficient in preparing structural drawings and detailing using
          AutoCAD, supported by advanced software tools, and adhering to IS
          Codes.
          <br />
          <br />
          <span className="about-span">Technical Competencies</span>
          <li>
            Extensive experience in the analysis and design of RCC and
            Steel-framed structures, including Steel Portal Frame Structures.
          </li>
          <li>
            Proficient in utilizing software such as ETABS, Tekla, and STAAD.Pro
            for structural analysis and design, supplemented by manual
            verification for accuracy.
          </li>
          <li>
            Expertise in evaluating designs for safety under Earthquake and Wind
            Loads in compliance with Indian Design Standards.
          </li>
          <li>
            Skilled in foundation system design using STAAD Foundation Advanced
            and CSI SAFE, covering raft foundations, pile foundations, combined
            footings, and raft-supported piles as per Indian Standards.
          </li>
          <br />
          <br />
          <span className="about-span">Educational Qualifications</span>
          <br />
          <li>Postgraduate in Structural Engineering</li>
          <li>Graduate in Civil Engineering, First Class with Distinction</li>
          <br />
          <br />
          <span className="about-span">Professional Philosophy</span>
          <br />
          Dedicated to honing technical expertise to excel as a proficient
          Structural Engineer. Committed to assessing and mitigating
          environmental impacts of projects, ensuring sustainability alongside
          engineering excellence.
        </p>
      </motion.div>
    </div>
  );
};

export default About;
