import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./WorkDialog.css";

const WorkDialog = ({ isOpen, onClose, project }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  if (!isOpen || !project) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog-container">
        <div className="dialog-content">
          <button className="dialog-close" onClick={onClose}>
            ×
          </button>

          <h2 className="project-title">{project.name}</h2>

          <div className="status-badge">Status: {project.status}</div>

          <div className="project-images">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={30}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
            >
              {project.gallery.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img} alt={`Project view ${index + 1}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          {/* <div className="project-details">
            <h3>Project Details</h3>
            <p>{project.details.description}</p>

            <h3>Specifications</h3>
            <ul>
              {Object.entries(project.details.specifications).map(
                ([key, value]) => (
                  <li key={key}>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, (str) => str.toUpperCase())}
                    : {value}
                  </li>
                )
              )}
            </ul>

            <h3>Additional Details</h3>
            <p>{project.details.additionalDetails}</p>

            <h3>Features</h3>
            <ul>
              {project.details.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WorkDialog;
